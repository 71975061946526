<ng-container *transloco="let t">
	<groupui-header>
		<groupui-grid marginType="custom" margin="0" class="flex-1">
			<groupui-grid-row>
				<groupui-grid-col offsetXxl="2" xxl="8" l="12">
					<div class="display-flex justify-content-space-between align-items-center">
						<div class="display-flex align-items-center flex-1">
							<a
								class="position-relative display-flex align-items-center text-decoration-none gap-groupui-spacing-3"
								[routerLink]="[PAGE_PATHS.HOME]">
								<img class="top-nav-bar-btr-logo" src="assets/images/BTR-Logo.svg" alt="BTR Logo" />
								<div class="position-relative version" [attr.data-version]="'v' + version">
									<groupui-headline class="color-white text-decoration-none show-lager-equal-than-L" heading="h5">
										{{ t('APP.NAME') }}
									</groupui-headline>
									<groupui-headline class="color-white text-decoration-none show-less-than-L" heading="h5">
										{{ t('APP.NAME_ABBR') }}
									</groupui-headline>
								</div>
							</a>
						</div>
						<div class="display-flex align-items-center gap-groupui-spacing-3 min-height-64px">
							<!-- Nav -->
							<groupui-action-area theme="vwag">
								<groupui-action-area-popover-item *ngIf="timezoneSelected$ | async as timezoneSelected" #popRef>
									<!-- User Icon -->
									<span slot="parent" class="display-flex align-items-center gap-groupui-spacing-3">
										<groupui-icon name="clock-32" theme="vwag"></groupui-icon> {{ timezoneSelected.name }}
										<span class="show-lager-equal-than-M">({{ timezoneSelected.utcOffset }})</span>
									</span>
									<!-- Action List -->
									<div slot="content" class="width-450px">
										<btr-timezone-select class="width-100p"></btr-timezone-select>
									</div>
								</groupui-action-area-popover-item>
								<groupui-action-area-popover-item>
									<!-- Info Icon -->
									<span slot="parent"><groupui-icon name="info-32"></groupui-icon></span>

									<!-- Action List -->
									<div slot="content" class="display-flex flex-direction-column min-width-300px width-100p">
										<div>
											<groupui-text size="caption" class="margin-top-1rem padding-left-1rem color-grey">{{
												t('APP.TOP_NAV.CONTACT')
											}}</groupui-text>
											<groupui-item size="s">
												<groupui-link data-test="link-email" target="blank" quiet="true" href="mailto: {{ supportEmail }}">
													<div class="display-flex justify-content-space-between align-items-center width-100p">
														<groupui-link quiet="true" class="padding-left-13rem">{{ t('APP.TOP_NAV.EMAIL') }}</groupui-link>
														<groupui-icon class="padding-right-13rem" name="email-24" theme="vwag"></groupui-icon>
													</div>
												</groupui-link>
											</groupui-item>
										</div>
										<groupui-item size="s">
											<groupui-link target="blank" quiet="true" [href]="complianceContent?.Imprint">
												<div class="display-flex justify-content-space-between align-items-center width-100p">
													<groupui-link quiet="true" class="padding-left-13rem">{{ t('APP.TOP_NAV.IMPRINT') }}</groupui-link>
													<groupui-icon
														class="padding-right-13rem color-petrol-primary"
														name="external-link-24"
														theme="vwag"></groupui-icon>
												</div>
											</groupui-link>
										</groupui-item>
										<div>
											<groupui-text size="caption" class="margin-top-1rem padding-left-1rem color-grey">{{
												t('APP.TOP_NAV.PRIVACY')
											}}</groupui-text>
											<groupui-item size="s">
												<groupui-link
													data-test="link-brand-employee-policy"
													target="blank"
													quiet="true"
													[href]="complianceContent?.VwBrandEmployeePolicy">
													<div class="display-flex justify-content-space-between align-items-center width-100p">
														<groupui-link quiet="true" class="padding-left-13rem">{{
															t('APP.TOP_NAV.BRAND_EMPLOYEE_POLICY')
														}}</groupui-link>
														<groupui-icon
															class="padding-right-13rem color-petrol-primary"
															name="external-link-24"
															theme="vwag"></groupui-icon>
													</div>
												</groupui-link>

												<groupui-link
													data-test="link-business-partner-policy"
													target="blank"
													quiet="true"
													[href]="complianceContent?.BusinessPartnerPolicy">
													<div class="display-flex justify-content-space-between align-items-center width-100p padding-top-05rem">
														<groupui-link quiet="true" class="padding-left-13rem">{{
															t('APP.TOP_NAV.BUSINESS_PARTNER_POLICY')
														}}</groupui-link>
														<groupui-icon
															class="padding-right-13rem color-petrol-primary"
															name="external-link-24"
															theme="vwag"></groupui-icon>
													</div>
												</groupui-link>

												<groupui-link
													data-test="link-cookie-policy"
													target="blank"
													quiet="true"
													[href]="complianceContent?.CookiePolicy">
													<div class="display-flex justify-content-space-between align-items-center width-100p padding-top-05rem">
														<groupui-link quiet="true" class="padding-left-13rem">{{ t('APP.TOP_NAV.COOKIE') }}</groupui-link>
														<groupui-icon
															class="padding-right-13rem color-petrol-primary"
															name="external-link-24"
															theme="vwag"></groupui-icon>
													</div>
												</groupui-link>

												<groupui-link
													data-test="link-privacy-policy"
													target="blank"
													quiet="true"
													[href]="complianceContent?.PrivacyPolicy">
													<div class="display-flex justify-content-space-between align-items-center width-100p padding-top-05rem">
														<groupui-link quiet="true" class="padding-left-13rem">{{
															t('APP.TOP_NAV.PRIVACY_POLICY')
														}}</groupui-link>
														<groupui-icon
															class="padding-right-13rem color-petrol-primary"
															name="external-link-24"
															theme="vwag"></groupui-icon>
													</div>
												</groupui-link>

												<groupui-link
													data-test="link-vw-group-employee-policy"
													target="blank"
													quiet="true"
													[href]="complianceContent?.VwGroupEmployeePolicy">
													<div class="display-flex justify-content-space-between align-items-center width-100p padding-top-05rem">
														<groupui-link quiet="true" class="padding-left-13rem">{{
															t('APP.TOP_NAV.VW_GROUP_EMPLOYEE_POLICY')
														}}</groupui-link>
														<groupui-icon
															class="padding-right-13rem color-petrol-primary"
															name="external-link-24"
															theme="vwag"></groupui-icon>
													</div>
												</groupui-link>
											</groupui-item>
										</div>
										<groupui-item size="s">
											<groupui-link data-test="link-system-help" target="blank" quiet="true" [href]="complianceContent?.SystemHelp">
												<div class="display-flex justify-content-space-between align-items-center width-100p">
													<groupui-link quiet="true" class="padding-left-13rem">{{ t('APP.TOP_NAV.SYSTEM_HELP') }}</groupui-link>
													<groupui-icon
														class="padding-right-13rem color-petrol-primary"
														name="external-link-24"
														theme="vwag"></groupui-icon>
												</div>
											</groupui-link>

											<groupui-link data-test="link-privacy-policy" target="blank" quiet="true" [href]="complianceContent?.TermsOfUse">
												<div class="display-flex justify-content-space-between align-items-center width-100p padding-top-05rem">
													<groupui-link quiet="true" class="padding-left-13rem">{{ t('APP.TOP_NAV.TERMS_OF_USE') }}</groupui-link>
													<groupui-icon
														class="padding-right-13rem color-petrol-primary"
														name="external-link-24"
														theme="vwag"></groupui-icon>
												</div>
											</groupui-link>

											<groupui-link
												data-test="link-third-party-licenses"
												target="blank"
												quiet="true"
												[href]="complianceContent?.ThirdPartyLicenses">
												<div class="display-flex justify-content-space-between align-items-center width-100p padding-top-05rem">
													<groupui-link quiet="true" class="padding-left-13rem">{{
														t('APP.TOP_NAV.THIRD_PARTY_LICENSES')
													}}</groupui-link>
													<groupui-icon
														class="padding-right-13rem color-petrol-primary"
														name="external-link-24"
														theme="vwag"></groupui-icon>
												</div>
											</groupui-link>

											<groupui-link
												data-test="link-group-whistleblower"
												target="blank"
												quiet="true"
												[href]="complianceContent?.GroupWhistleblower">
												<div class="display-flex justify-content-space-between align-items-center width-100p padding-top-05rem">
													<groupui-link quiet="true" class="padding-left-13rem">{{ t('APP.TOP_NAV.VW_WHISTLEBLOWER') }}</groupui-link>
													<groupui-icon
														class="padding-right-13rem color-petrol-primary"
														name="external-link-24"
														theme="vwag"></groupui-icon>
												</div>
											</groupui-link>
										</groupui-item>
									</div>
								</groupui-action-area-popover-item>

								<groupui-action-area-popover-item>
									<!-- User Icon -->
									<span slot="parent"><groupui-icon name="user-32" theme="vwag"></groupui-icon></span>

									<!-- Action List -->
									<div slot="content" class="display-flex flex-direction-column min-width-300px">
										<groupui-item size="s" (click)="onLogout()">
											<groupui-link quiet="true">
												<div class="display-flex justify-content-space-between align-items-center width-100p">
													<groupui-link quiet="true" class="padding-left-13rem color-petrol-primary">{{
														t('APP.TOP_NAV.USER_SIGN_OUT')
													}}</groupui-link>
													<groupui-icon class="padding-right-13rem" name="logout-24" theme="vwag"></groupui-icon>
												</div>
											</groupui-link>
										</groupui-item>
									</div>
								</groupui-action-area-popover-item>
							</groupui-action-area>
						</div>
					</div>
				</groupui-grid-col>
			</groupui-grid-row>
		</groupui-grid>
	</groupui-header>
</ng-container>

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoRootModule } from './i18n/transloco-root.module';
import { TranslocoTestingModule } from '@ngneat/transloco';

@NgModule({
	imports: [CommonModule, HttpClientModule, TranslocoRootModule, ReactiveFormsModule, TranslocoTestingModule],
	exports: [TranslocoRootModule, ReactiveFormsModule, TranslocoTestingModule],
})
export class CoreModule {}

export enum MASTER_DATA_ACTIONS {
	UPDATE = 'UPDATE',
	ADDITIONAL_DATA = 'ADDITIONAL_DATA',
	COMPONENT_DETAILS = 'COMPONENT_DETAILS',
	CREATE_PART_TYPE = 'CREATE_PART_TYPE',
	DELETE_PART_TYPE = 'DELETE_PART_TYPE',
	MANAGE_ASSIGNED_PARTNER_GROUP = 'MANAGE_ASSIGNED_PARTNER_GROUP',
	MANAGE_PART_TYPE_READ_PERMISSION_MANUALLY = 'MANAGE_PART_TYPE_READ_PERMISSION_MANUALLY',
	CREATE_PARTNER_GROUP = 'CREATE_PARTNER_GROUP',
	DELETE_PARTNER_GROUP = 'DELETE_PARTNER_GROUP',
	EDIT_PARTNER_GROUP_PART_TYPE_READ_PERMISSION = 'EDIT_PARTNER_GROUP_PART_TYPE_READ_PERMISSION',
	CREATE_PARTNER = 'CREATE_PARTNER',
	DELETE_PARTNER = 'DELETE_PARTNER',
	EDIT_PERMITTED_CUSTOMER = 'EDIT_PERMITTED_CUSTOMER',
}

export enum READ_ACCESS_EDIT_PROPERTY {
	ADDITIONAL_DATA = 'ADDITIONAL_DATA',
	COMPONENT_DETAILS = 'COMPONENT_DETAILS',
}

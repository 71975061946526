import { Component, Input } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ComplianceContent } from '@core/graphql/generated-types';
import { CoreModule } from '@core/core.module';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { environment } from '@environments/environment';

@Component({
	selector: 'btr-home-footer',
	standalone: true,
	imports: [CommonModule, GroupUiModule, CoreModule],
	templateUrl: './home-footer.component.html',
})
export class HomeFooterComponent {
	@Input() complianceContent!: ComplianceContent | null;
	supportEmail = `mailto:${environment.config.contact.email}`;
}

<ng-container *transloco="let t; read: 'APP.FOOTER'">
	<div class="display-flex flex-direction-column align-items-center gap-groupui-spacing-7 padding-bottom-2rem">
		<!-- Upper footer area -->
		<div class="display-flex flex-direction-column align-items-center gap-groupui-spacing-3 width-100p">
			<groupui-divider></groupui-divider>
			<!-- Actions -->
			<div class="display-flex align-items-center">
				<groupui-action-area theme="vwag">
					<groupui-action-area-popover-item placement="top">
						<span slot="parent" class="width-100px text-align-center">
							<groupui-text class="color-primary">{{ t('CONTACT') }}</groupui-text>
						</span>
						<div slot="content" class="display-flex flex-direction-column min-width-300px">
							<groupui-item size="s">
								<groupui-link data-test="link-email" target="blank" quiet="true" href="mailto: {{ supportEmail }}">
									<div class="display-flex justify-content-space-between align-items-center width-100p">
										<groupui-link quiet="true" class="padding-left-13rem">{{ t('EMAIL') }}</groupui-link>
										<groupui-icon class="padding-right-13rem" name="email-24" theme="vwag"></groupui-icon>
									</div>
								</groupui-link>
							</groupui-item>
						</div>
					</groupui-action-area-popover-item>
				</groupui-action-area>
				<groupui-divider vertical class="height-2rem margin-left-05rem margin-right-05rem"></groupui-divider>
				<groupui-link
					class="width-100px text-align-center padding-inline-075rem"
					data-test="link-imprint"
					target="blank"
					quiet="true"
					[href]="complianceContent?.Imprint">
					{{ t('IMPRINT') }}
				</groupui-link>
				<groupui-divider vertical class="height-2rem margin-left-05rem margin-right-05rem"></groupui-divider>
				<groupui-action-area theme="vwag">
					<groupui-action-area-popover-item>
						<!-- Info Icon -->
						<span slot="parent" class="width-100px text-align-center">
							<groupui-text class="color-primary">{{ t('PRIVACY') }}</groupui-text>
						</span>
						<!-- Action List -->
						<div slot="content" class="display-flex flex-direction-column min-width-300px">
							<groupui-item size="s">
								<groupui-link
									data-test="link-brand-employee-policy"
									target="blank"
									quiet="true"
									[href]="complianceContent?.VwBrandEmployeePolicy">
									<div class="display-flex justify-content-space-between align-items-center width-100p">
										<groupui-link quiet="true" class="padding-left-13rem">{{ t('BRAND_EMPLOYEE_POLICY') }}</groupui-link>
										<groupui-icon class="padding-right-13rem color-petrol-primary" name="external-link-24" theme="vwag"></groupui-icon>
									</div>
								</groupui-link>

								<groupui-link
									data-test="link-business-partner-policy"
									target="blank"
									quiet="true"
									[href]="complianceContent?.BusinessPartnerPolicy">
									<div class="display-flex justify-content-space-between align-items-center width-100p padding-top-05rem">
										<groupui-link quiet="true" class="padding-left-13rem">{{ t('BUSINESS_PARTNER_POLICY') }}</groupui-link>
										<groupui-icon class="padding-right-13rem color-petrol-primary" name="external-link-24" theme="vwag"></groupui-icon>
									</div>
								</groupui-link>

								<groupui-link data-test="link-privacy-policy" target="blank" quiet="true" [href]="complianceContent?.PrivacyPolicy">
									<div class="display-flex justify-content-space-between align-items-center width-100p padding-top-05rem">
										<groupui-link quiet="true" class="padding-left-13rem">{{ t('PRIVACY_POLICY') }}</groupui-link>
										<groupui-icon class="padding-right-13rem color-petrol-primary" name="external-link-24" theme="vwag"></groupui-icon>
									</div>
								</groupui-link>

								<groupui-link
									data-test="link-vw-group-employee-policy"
									target="blank"
									quiet="true"
									[href]="complianceContent?.VwGroupEmployeePolicy">
									<div class="display-flex justify-content-space-between align-items-center width-100p padding-top-05rem">
										<groupui-link quiet="true" class="padding-left-13rem">{{ t('VW_GROUP_EMPLOYEE_POLICY') }}</groupui-link>
										<groupui-icon class="padding-right-13rem color-petrol-primary" name="external-link-24" theme="vwag"></groupui-icon>
									</div>
								</groupui-link>
							</groupui-item>
						</div>
					</groupui-action-area-popover-item>
				</groupui-action-area>
				<groupui-divider vertical class="height-2rem margin-left-05rem margin-right-05rem"></groupui-divider>
				<groupui-link
					class="width-100px text-align-center padding-inline-075rem"
					data-test="link-system-help"
					quiet="true"
					target="blank"
					[href]="complianceContent?.SystemHelp">
					{{ t('SYSTEM_HELP') }}
				</groupui-link>
				<groupui-divider vertical class="height-2rem margin-left-05rem margin-right-05rem"></groupui-divider>
				<groupui-link
					class="width-100px text-align-center padding-inline-075rem"
					data-test="link-terms-of-use"
					target="blank"
					quiet="true"
					[href]="complianceContent?.TermsOfUse">
					{{ t('TERMS_OF_USE') }}
				</groupui-link>
			</div>
			<groupui-divider></groupui-divider>
		</div>
		<!-- VW Logo -->
		<div class="footer-image display-flex">
			<img src="assets/images/vwag_footer_image.svg" alt="vwag footer image" />
		</div>
	</div>
</ng-container>

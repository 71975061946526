/* eslint-disable sort-imports */
import { AppRoutingModule, routes } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { AppComponent } from './app.component';
import { AppUpdateModalComponent } from '@shared/app-update-modal/app-update-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { GraphQLModule } from './graphql.module';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { NotificationCardComponent } from '@shared/notification-card/notification-card.component';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TokenInterceptor } from '@core/interceptors/token.interceptor';
import { TranslocoRootModule } from './core/i18n/transloco-root.module';
import { environment } from '@environments/environment';
import { ClientVersionInterceptor } from '@core/interceptors/client-version.interceptor';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		LoggerModule.forRoot({
			level: NgxLoggerLevel.ERROR,
		}),
		RouterModule.forRoot(
			routes,
			{ enableTracing: false }, // <-- debugging purposes only
		),
		GraphQLModule,
		HttpClientModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.config.serviceWorker.enabled,
			registrationStrategy: environment.config.serviceWorker.registrationStrategy,
		}),
		HomeComponent,
		NotificationCardComponent,
		GroupUiModule,
		CoreModule,
		AppUpdateModalComponent,
		TranslocoRootModule,
	],
	providers: [
		// Injected as optional parameter for auth service. This is required provide config for local development
		{ provide: 'env', useValue: environment },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ClientVersionInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

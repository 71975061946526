// DO NOT EDIT!!!
// This file is automatically generated from the graphql mock-server.
// prettier-ignore
/* eslint-disable */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	AWSDateTime: string;
	AWSJSON: string;
};

export type AssemblyGroup = {
	__typename?: 'AssemblyGroup';
	ContainerGBT?: Maybe<Scalars['String']>;
	ContainerVW?: Maybe<Scalars['String']>;
	ParentPosition?: Maybe<Scalars['String']>;
};

export type AvailablePartnerResponse = {
	__typename?: 'AvailablePartnerResponse';
	PartnerId?: Maybe<Scalars['String']>;
	PartnerLocation?: Maybe<Scalars['String']>;
	PartnerName?: Maybe<Scalars['String']>;
	PartnerStatus?: Maybe<PartnerStatus>;
	PartnerType?: Maybe<PartnerType>;
	PermittedCustomers?: Maybe<Array<Maybe<PermittedCustomer>>>;
};

export type BetweenFilter = {
	end?: InputMaybe<Scalars['String']>;
	start?: InputMaybe<Scalars['String']>;
};

export type CompanyInfo = {
	__typename?: 'CompanyInfo';
	DUNS?: Maybe<Scalars['String']>;
	Location?: Maybe<Scalars['String']>;
	Name?: Maybe<Scalars['String']>;
};

export type ComplianceContent = {
	__typename?: 'ComplianceContent';
	BusinessPartnerPolicy?: Maybe<Scalars['String']>;
	CookiePolicy?: Maybe<Scalars['String']>;
	GroupWhistleblower?: Maybe<Scalars['String']>;
	Imprint?: Maybe<Scalars['String']>;
	PrivacyPolicy?: Maybe<Scalars['String']>;
	SystemHelp?: Maybe<Scalars['String']>;
	TermsOfUse?: Maybe<Scalars['String']>;
	ThirdPartyLicenses?: Maybe<Scalars['String']>;
	VwBrandEmployeePolicy?: Maybe<Scalars['String']>;
	VwGroupEmployeePolicy?: Maybe<Scalars['String']>;
};

export type Component = {
	__typename?: 'Component';
	AssemblerPartner?: Maybe<Partner>;
	AssemblerPartnerId?: Maybe<Scalars['String']>;
	AssemblyGroup?: Maybe<AssemblyGroup>;
	AssemblyLine?: Maybe<Scalars['String']>;
	ComponentId?: Maybe<Scalars['String']>;
	ContractId?: Maybe<Scalars['String']>;
	CreationDate?: Maybe<Scalars['AWSDateTime']>;
	CurrentAttachmentsReferences?: Maybe<Array<Maybe<XmlReference>>>;
	CurrentXmlReference?: Maybe<XmlReference>;
	DeletionDate?: Maybe<Scalars['String']>;
	GBTNumber?: Maybe<Scalars['String']>;
	IsLotPart?: Maybe<Scalars['Boolean']>;
	KsuClass?: Maybe<Scalars['String']>;
	KsuTTL?: Maybe<Scalars['String']>;
	LastModified?: Maybe<Scalars['AWSDateTime']>;
	ManufactureDate?: Maybe<Scalars['AWSDateTime']>;
	ManufacturerPartner?: Maybe<Partner>;
	ManufacturerPartnerId?: Maybe<Scalars['String']>;
	ParentComponentId?: Maybe<Scalars['String']>;
	PartNumber?: Maybe<Scalars['String']>;
	PartType?: Maybe<Scalars['String']>;
	Plant?: Maybe<Scalars['String']>;
	Position?: Maybe<Scalars['String']>;
	RegistrationDate?: Maybe<Scalars['AWSDateTime']>;
	SerialNumber?: Maybe<Scalars['String']>;
	Station?: Maybe<Scalars['String']>;
	Status?: Maybe<ComponentState>;
	SubComponents?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentFilter = {
	assemblyLine?: InputMaybe<StringFilter>;
	componentState?: InputMaybe<ComponentStateFilter>;
	lifecycle?: InputMaybe<LifecycleFilter>;
	partNumber?: InputMaybe<StringFilter>;
	plant?: InputMaybe<StringFilter>;
	station?: InputMaybe<StringFilter>;
	timeRangeEndTime: Scalars['AWSDateTime'];
	timeRangeProperty: ComponentTimeRangeProperty;
	timeRangeStartTime: Scalars['AWSDateTime'];
};

export type ComponentHistory = {
	__typename?: 'ComponentHistory';
	ChangeComment?: Maybe<Scalars['String']>;
	ChangeDate?: Maybe<Scalars['String']>;
	FileName?: Maybe<Scalars['String']>;
	PartnerId?: Maybe<Scalars['String']>;
	PartnerName?: Maybe<Scalars['String']>;
	UserId?: Maybe<Scalars['String']>;
	VersionId?: Maybe<Scalars['String']>;
};

export type ComponentId = {
	ComponentId?: InputMaybe<Scalars['String']>;
};

export enum ComponentLifecycle {
	FirstUse = 'FIRST_USE',
	Recycled = 'RECYCLED',
	Scrapped = 'SCRAPPED',
	ScrappedByTopComponent = 'SCRAPPED_BY_TOP_COMPONENT',
	SecondLife = 'SECOND_LIFE',
	Sold = 'SOLD',
}

export type ComponentLinkRequest = {
	ComponentId?: InputMaybe<Scalars['String']>;
	ParentComponentId?: InputMaybe<Scalars['String']>;
};

export type ComponentList = {
	__typename?: 'ComponentList';
	items?: Maybe<Array<Maybe<SimpleComponent>>>;
	itemsLimit?: Maybe<Scalars['Int']>;
	limitExceeded?: Maybe<Scalars['Boolean']>;
	notFound?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentListCsv = {
	__typename?: 'ComponentListCsv';
	csvURI?: Maybe<Scalars['String']>;
	notFound?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentReadAccess = {
	__typename?: 'ComponentReadAccess';
	AdditionalData?: Maybe<ReadAccess>;
	ComponentDetail?: Maybe<ReadAccess>;
	ContractId?: Maybe<Scalars['String']>;
	Partner?: Maybe<Partner>;
};

export type ComponentRequest = {
	XMLFile?: InputMaybe<Scalars['String']>;
	XMLFileName?: InputMaybe<Scalars['String']>;
};

export type ComponentResponse = {
	__typename?: 'ComponentResponse';
	ComponentId?: Maybe<Scalars['String']>;
	ContractId?: Maybe<Scalars['String']>;
};

export type ComponentState = {
	__typename?: 'ComponentState';
	isBlocked?: Maybe<Scalars['Boolean']>;
	isBlockedBySubcomponent?: Maybe<Scalars['Boolean']>;
	isComplete?: Maybe<Scalars['Boolean']>;
	isLinkedToParent?: Maybe<Scalars['Boolean']>;
	lifecycle?: Maybe<ComponentLifecycle>;
};

export type ComponentStateFilter = {
	_eq?: InputMaybe<ComponentStateInput>;
};

export type ComponentStateInput = {
	isBlocked?: InputMaybe<Scalars['Boolean']>;
	isBlockedBySubcomponent?: InputMaybe<Scalars['Boolean']>;
	isComplete?: InputMaybe<Scalars['Boolean']>;
	isLinkedToParent?: InputMaybe<Scalars['Boolean']>;
};

export enum ComponentTimeRangeProperty {
	LastModifiedTime = 'LAST_MODIFIED_TIME',
	ManufactureTime = 'MANUFACTURE_TIME',
	RegistrationTime = 'REGISTRATION_TIME',
}

export type ComponentWriteAccess = {
	__typename?: 'ComponentWriteAccess';
	Assembler?: Maybe<Array<Maybe<Partner>>>;
	ComponentWriteAccessByContract?: Maybe<WriteAccess>;
	ContractId?: Maybe<Scalars['String']>;
	Partner?: Maybe<Partner>;
	PartnerGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ComponentXml = {
	__typename?: 'ComponentXml';
	XmlUrl?: Maybe<Scalars['String']>;
};

export type Contract = {
	__typename?: 'Contract';
	Approver?: Maybe<Scalars['ID']>;
	ContractId: Scalars['String'];
	Creator?: Maybe<Scalars['ID']>;
	Customer?: Maybe<Scalars['ID']>;
	KsuClass?: Maybe<Scalars['String']>;
	KsuTTL?: Maybe<Scalars['String']>;
	LastModified?: Maybe<Scalars['String']>;
	PartNumber?: Maybe<Scalars['String']>;
	PartType?: Maybe<Scalars['String']>;
	State?: Maybe<ContractState>;
	Supplier?: Maybe<Scalars['ID']>;
	TransactionHistory?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ContractFullDetails = {
	__typename?: 'ContractFullDetails';
	ApproverPartner?: Maybe<Partner>;
	ApproverPartnerId?: Maybe<Scalars['ID']>;
	BlobDefinition?: Maybe<Array<Maybe<Scalars['AWSJSON']>>>;
	ContractId: Scalars['String'];
	CreatorPartner?: Maybe<Partner>;
	CreatorPartnerId?: Maybe<Scalars['ID']>;
	CustomerPartner?: Maybe<Partner>;
	CustomerPartnerId?: Maybe<Scalars['ID']>;
	KsuClass?: Maybe<Scalars['String']>;
	KsuTTL?: Maybe<Scalars['String']>;
	LastModified?: Maybe<Scalars['String']>;
	PartNumber?: Maybe<Scalars['String']>;
	PartType?: Maybe<Scalars['String']>;
	State?: Maybe<ContractState>;
	SupplierPartner?: Maybe<Partner>;
	SupplierPartnerId?: Maybe<Scalars['ID']>;
	TransactionHistory?: Maybe<Array<Maybe<Scalars['String']>>>;
	XSDSchemaReference?: Maybe<Scalars['AWSJSON']>;
};

export type ContractHistory = {
	__typename?: 'ContractHistory';
	ChangeComment?: Maybe<Scalars['String']>;
	ChangeDate?: Maybe<Scalars['String']>;
	PartnerId?: Maybe<Scalars['String']>;
	PartnerName?: Maybe<Scalars['String']>;
	UserId?: Maybe<Scalars['String']>;
};

export type ContractId = {
	__typename?: 'ContractId';
	ContractId?: Maybe<Scalars['String']>;
};

export type ContractRequest = {
	Customer: Scalars['String'];
	PartNumber: Scalars['String'];
	PartType: Scalars['String'];
	Supplier: Scalars['String'];
};

export enum ContractState {
	Activated = 'ACTIVATED',
	Approved = 'APPROVED',
	Archived = 'ARCHIVED',
	Deactivated = 'DEACTIVATED',
	Draft = 'DRAFT',
	InApproval = 'IN_APPROVAL',
	New = 'NEW',
	Rejected = 'REJECTED',
}

export type ContractStateResponse = {
	__typename?: 'ContractStateResponse';
	ContractId?: Maybe<Scalars['String']>;
	State?: Maybe<ContractState>;
};

export type ContractXsd = {
	__typename?: 'ContractXsd';
	XsdUrl?: Maybe<Scalars['String']>;
};

export type CreatePartnerGroupRequest = {
	PartnerGroupName: Scalars['String'];
};

export type CreatePartnerRequest = {
	PartnerId: Scalars['String'];
	PartnerLocation?: InputMaybe<Scalars['String']>;
	PartnerName?: InputMaybe<Scalars['String']>;
	PartnerType?: InputMaybe<PartnerType>;
};

export type CreatePartnerResponse = {
	__typename?: 'CreatePartnerResponse';
	PartnerId?: Maybe<Scalars['String']>;
	PartnerLocation?: Maybe<Scalars['String']>;
	PartnerName?: Maybe<Scalars['String']>;
	PartnerType?: Maybe<PartnerType>;
	PermittedCustomers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum Criteria {
	GbtNumber = 'GBT_NUMBER',
	SerialNumber = 'SERIAL_NUMBER',
}

export type Data = {
	__typename?: 'Data';
	key?: Maybe<Scalars['String']>;
	value?: Maybe<Scalars['String']>;
};

export type DeleteContractRequest = {
	ContractId: Scalars['String'];
};

export type DeletePartnerGroupRequest = {
	PartnerGroupName: Scalars['String'];
};

export type DeletePartnerRequest = {
	PartnerId: Scalars['String'];
};

export type DeletePartnerResponse = {
	__typename?: 'DeletePartnerResponse';
	PartnerId?: Maybe<Scalars['String']>;
};

export type GetCompanyDataRequest = {
	DUNS?: InputMaybe<Scalars['String']>;
};

export type GetContracts = {
	__typename?: 'GetContracts';
	ContractId?: Maybe<Scalars['String']>;
};

export type GetContractsByPartnerIdRequest = {
	ContractStates?: InputMaybe<Array<InputMaybe<ContractState>>>;
};

export type GetContractsByPartnerIdResponse = {
	__typename?: 'GetContractsByPartnerIdResponse';
	Approver?: Maybe<Scalars['String']>;
	ContractId?: Maybe<Scalars['String']>;
	Creator?: Maybe<Scalars['String']>;
	Customer?: Maybe<Scalars['String']>;
	LastModified?: Maybe<Scalars['String']>;
	PartNumber?: Maybe<Scalars['String']>;
	PartType?: Maybe<Scalars['String']>;
	State?: Maybe<ContractState>;
	Supplier?: Maybe<Scalars['String']>;
};

export type GetUserDataRequest = {
	ProfileId?: InputMaybe<Scalars['String']>;
};

export type LifecycleFilter = {
	_eq?: InputMaybe<ComponentLifecycle>;
	_in?: InputMaybe<Array<InputMaybe<ComponentLifecycle>>>;
};

export type Mutation = {
	__typename?: 'Mutation';
	blockComponent?: Maybe<Scalars['Boolean']>;
	createPartType?: Maybe<PartType>;
	createPartner?: Maybe<CreatePartnerResponse>;
	createPartnerGroup?: Maybe<PartnerGroup>;
	createProductPassTemplate?: Maybe<ProductPassTemplateId>;
	deleteContract?: Maybe<ContractId>;
	deletePartType?: Maybe<PartType>;
	deletePartner?: Maybe<DeletePartnerResponse>;
	deletePartnerGroup?: Maybe<PartnerGroup>;
	linkComponent?: Maybe<Scalars['Boolean']>;
	registerComponent?: Maybe<ComponentResponse>;
	registerContract?: Maybe<Contract>;
	unblockComponent?: Maybe<Scalars['Boolean']>;
	unlinkComponent?: Maybe<Scalars['Boolean']>;
	updateComponent?: Maybe<ComponentResponse>;
	updateComponentLifecycle?: Maybe<ComponentResponse>;
	updateComponentReadAccess?: Maybe<ComponentReadAccess>;
	updateComponentWriteAccess?: Maybe<ComponentWriteAccess>;
	updateContractState?: Maybe<ContractStateResponse>;
	updateContractXSD?: Maybe<ContractFullDetails>;
	updatePartnerGroup?: Maybe<PartnerGroup>;
	updatePartnerPartTypeReadAccess?: Maybe<PartnerPartTypeReadAccess>;
	updatePartnerRelation?: Maybe<UpdatePartnerRelationResponse>;
};

export type MutationBlockComponentArgs = {
	input?: InputMaybe<ComponentId>;
};

export type MutationCreatePartTypeArgs = {
	input: PartTypeRequest;
};

export type MutationCreatePartnerArgs = {
	input: CreatePartnerRequest;
};

export type MutationCreatePartnerGroupArgs = {
	input: CreatePartnerGroupRequest;
};

export type MutationCreateProductPassTemplateArgs = {
	description?: InputMaybe<Scalars['String']>;
	jsonFileBase64Encoded: Scalars['String'];
	law: Scalars['String'];
};

export type MutationDeleteContractArgs = {
	input: DeleteContractRequest;
};

export type MutationDeletePartTypeArgs = {
	input: PartTypeRequest;
};

export type MutationDeletePartnerArgs = {
	input: DeletePartnerRequest;
};

export type MutationDeletePartnerGroupArgs = {
	input: DeletePartnerGroupRequest;
};

export type MutationLinkComponentArgs = {
	input?: InputMaybe<ComponentLinkRequest>;
};

export type MutationRegisterComponentArgs = {
	input?: InputMaybe<ComponentRequest>;
};

export type MutationRegisterContractArgs = {
	input: ContractRequest;
};

export type MutationUnblockComponentArgs = {
	input?: InputMaybe<ComponentId>;
};

export type MutationUnlinkComponentArgs = {
	input?: InputMaybe<ComponentLinkRequest>;
};

export type MutationUpdateComponentArgs = {
	ComponentId?: InputMaybe<Scalars['String']>;
	componentId?: InputMaybe<Scalars['String']>;
	input?: InputMaybe<ComponentRequest>;
};

export type MutationUpdateComponentLifecycleArgs = {
	componentId: Scalars['String'];
	lifecycle: UpdateComponentLifecycle;
};

export type MutationUpdateComponentReadAccessArgs = {
	contractId: Scalars['String'];
	input: UpdateComponentReadAccessRequest;
};

export type MutationUpdateComponentWriteAccessArgs = {
	contractId: Scalars['String'];
	input: UpdateComponentWriteAccessRequest;
};

export type MutationUpdateContractStateArgs = {
	input: UpdateContractStateRequest;
};

export type MutationUpdateContractXsdArgs = {
	input: UpdateContractXsdRequest;
};

export type MutationUpdatePartnerGroupArgs = {
	input: UpdatePartnerGroupRequest;
};

export type MutationUpdatePartnerPartTypeReadAccessArgs = {
	input: UpdatePartTypeReadAccessRequest;
	partnerId: Scalars['String'];
};

export type MutationUpdatePartnerRelationArgs = {
	input: UpdatePartnerRelationRequest;
};

export type PartType = {
	__typename?: 'PartType';
	InUse?: Maybe<Scalars['Boolean']>;
	PartTypeName?: Maybe<Scalars['String']>;
	RegistrationDate?: Maybe<Scalars['AWSDateTime']>;
	SubPartTypeSet?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PartTypeReadAccess = {
	__typename?: 'PartTypeReadAccess';
	AutomaticallySet?: Maybe<Array<Maybe<Scalars['String']>>>;
	ManuallySet?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PartTypeRequest = {
	PartTypeName: Scalars['String'];
};

export type Partner = {
	__typename?: 'Partner';
	PartnerId?: Maybe<Scalars['String']>;
	PartnerLocation?: Maybe<Scalars['String']>;
	PartnerName?: Maybe<Scalars['String']>;
	PartnerStatus?: Maybe<PartnerStatus>;
	PartnerType?: Maybe<PartnerType>;
};

export type PartnerGroup = {
	__typename?: 'PartnerGroup';
	PartTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
	PartnerGroupName?: Maybe<Scalars['String']>;
};

export type PartnerPartTypeReadAccess = {
	__typename?: 'PartnerPartTypeReadAccess';
	PartTypeReadAccess?: Maybe<PartTypeReadAccess>;
	PartnerGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
	PartnerId?: Maybe<Scalars['String']>;
	PartnerLocation?: Maybe<Scalars['String']>;
	PartnerName?: Maybe<Scalars['String']>;
	PartnerStatus?: Maybe<PartnerStatus>;
	PartnerType?: Maybe<PartnerType>;
};

export enum PartnerStatus {
	Active = 'ACTIVE',
	Internal = 'INTERNAL',
	Outdated = 'OUTDATED',
}

export enum PartnerType {
	External = 'EXTERNAL',
	Internal = 'INTERNAL',
}

export type PermittedCustomer = {
	__typename?: 'PermittedCustomer';
	PartnerId?: Maybe<Scalars['String']>;
	PartnerLocation?: Maybe<Scalars['String']>;
	PartnerName?: Maybe<Scalars['String']>;
	PartnerStatus?: Maybe<PartnerStatus>;
	PartnerType?: Maybe<PartnerType>;
};

export type ProductPass = {
	__typename?: 'ProductPass';
	Description?: Maybe<Scalars['String']>;
	LastModified?: Maybe<Scalars['AWSDateTime']>;
	Name?: Maybe<Scalars['String']>;
	PartNumber?: Maybe<Scalars['String']>;
	PartType?: Maybe<Scalars['String']>;
	ProductPassId?: Maybe<Scalars['String']>;
	State?: Maybe<ProductPassState>;
	Template?: Maybe<Scalars['String']>;
};

export enum ProductPassState {
	Activated = 'ACTIVATED',
	Approved = 'APPROVED',
	Archived = 'ARCHIVED',
	Deactivated = 'DEACTIVATED',
	Draft = 'DRAFT',
	InApproval = 'IN_APPROVAL',
	Outdated = 'OUTDATED',
	Rejected = 'REJECTED',
}

export type ProductPassTemplate = {
	__typename?: 'ProductPassTemplate';
	Description?: Maybe<Scalars['String']>;
	LastModified?: Maybe<Scalars['AWSDateTime']>;
	Law?: Maybe<Scalars['String']>;
	ProductPassTemplateId?: Maybe<Scalars['String']>;
	ProductPasses?: Maybe<Array<Maybe<ProductPass>>>;
	State?: Maybe<ProductPassTemplateState>;
};

export type ProductPassTemplateFilter = {
	lastModifiedEndTime?: InputMaybe<Scalars['AWSDateTime']>;
	lastModifiedStartTime?: InputMaybe<Scalars['AWSDateTime']>;
	law?: InputMaybe<StringFilter>;
	state?: InputMaybe<ProductPassTemplateStateFilter>;
};

export type ProductPassTemplateId = {
	__typename?: 'ProductPassTemplateId';
	templateId?: Maybe<Scalars['String']>;
};

export type ProductPassTemplateList = {
	__typename?: 'ProductPassTemplateList';
	items?: Maybe<Array<Maybe<ProductPassTemplate>>>;
};

export enum ProductPassTemplateState {
	Activated = 'ACTIVATED',
	Archived = 'ARCHIVED',
	Deactivated = 'DEACTIVATED',
	Draft = 'DRAFT',
}

export type ProductPassTemplateStateFilter = {
	_eq?: InputMaybe<ProductPassTemplateState>;
	_in?: InputMaybe<Array<InputMaybe<ProductPassTemplateState>>>;
};

export type Query = {
	__typename?: 'Query';
	componentHistoryById?: Maybe<Array<Maybe<ComponentHistory>>>;
	componentReadAccess?: Maybe<Array<Maybe<ComponentReadAccess>>>;
	componentReadAccessById?: Maybe<ComponentReadAccess>;
	componentWriteAccess?: Maybe<Array<Maybe<ComponentWriteAccess>>>;
	componentWriteAccessById?: Maybe<ComponentWriteAccess>;
	componentsByContractId?: Maybe<ComponentList>;
	componentsByOtherCriteria?: Maybe<ComponentList>;
	componentsCsvByContractId?: Maybe<ComponentListCsv>;
	componentsCsvByOtherCriteria?: Maybe<ComponentListCsv>;
	contractByPermission?: Maybe<Array<Maybe<Contract>>>;
	contractHistoryById?: Maybe<Array<Maybe<ContractHistory>>>;
	exportComponentXmlById?: Maybe<ComponentXml>;
	exportContractXsdById?: Maybe<ContractXsd>;
	getAvailableCustomersBySupplierId?: Maybe<Array<Maybe<AvailablePartnerResponse>>>;
	getAvailablePartner?: Maybe<Array<Maybe<AvailablePartnerResponse>>>;
	getAvailableSuppliers?: Maybe<Array<Maybe<AvailablePartnerResponse>>>;
	getCompanyData?: Maybe<CompanyInfo>;
	getComplianceContent?: Maybe<ComplianceContent>;
	getComponentAdditionalData?: Maybe<Array<Maybe<Data>>>;
	getComponentById?: Maybe<Component>;
	getComponentDetails?: Maybe<Xml>;
	getComponentSubcomponents?: Maybe<Array<Maybe<Subcomponent>>>;
	getContractFullDetailsById?: Maybe<ContractFullDetails>;
	getContractsByPartnerId?: Maybe<Array<Maybe<GetContractsByPartnerIdResponse>>>;
	getPartTypes?: Maybe<Array<Maybe<PartType>>>;
	getPartnerGroupByName?: Maybe<PartnerGroup>;
	getPartnerGroups?: Maybe<Array<Maybe<PartnerGroup>>>;
	getUserData?: Maybe<UserInfo>;
	partnerPartTypeReadAccess?: Maybe<Array<Maybe<PartnerPartTypeReadAccess>>>;
	partnerPartTypeReadAccessById?: Maybe<PartnerPartTypeReadAccess>;
	productPassTemplate?: Maybe<ProductPassTemplateList>;
};

export type QueryComponentHistoryByIdArgs = {
	componentId: Scalars['String'];
};

export type QueryComponentReadAccessByIdArgs = {
	contractId: Scalars['String'];
};

export type QueryComponentWriteAccessByIdArgs = {
	contractId: Scalars['String'];
};

export type QueryComponentsByContractIdArgs = {
	contractId: Scalars['String'];
	where: ComponentFilter;
};

export type QueryComponentsByOtherCriteriaArgs = {
	criteriaType: Criteria;
	criteriaValue: Array<InputMaybe<Scalars['String']>>;
};

export type QueryComponentsCsvByContractIdArgs = {
	contractId: Scalars['String'];
	where: ComponentFilter;
};

export type QueryComponentsCsvByOtherCriteriaArgs = {
	criteriaType: Criteria;
	criteriaValue: Array<InputMaybe<Scalars['String']>>;
};

export type QueryContractHistoryByIdArgs = {
	contractId: Scalars['String'];
};

export type QueryExportComponentXmlByIdArgs = {
	ComponentId: Scalars['String'];
	Version?: InputMaybe<Scalars['String']>;
};

export type QueryExportContractXsdByIdArgs = {
	ContractId: Scalars['String'];
};

export type QueryGetAvailableCustomersBySupplierIdArgs = {
	PartnerId: Scalars['String'];
};

export type QueryGetCompanyDataArgs = {
	input?: InputMaybe<GetCompanyDataRequest>;
};

export type QueryGetComponentAdditionalDataArgs = {
	input: ComponentId;
};

export type QueryGetComponentByIdArgs = {
	ComponentId: Scalars['String'];
};

export type QueryGetComponentDetailsArgs = {
	input: ComponentId;
};

export type QueryGetComponentSubcomponentsArgs = {
	input: ComponentId;
};

export type QueryGetContractFullDetailsByIdArgs = {
	ContractId: Scalars['String'];
};

export type QueryGetContractsByPartnerIdArgs = {
	input?: InputMaybe<GetContractsByPartnerIdRequest>;
};

export type QueryGetPartnerGroupByNameArgs = {
	PartnerGroupName: Scalars['String'];
};

export type QueryGetUserDataArgs = {
	input: GetUserDataRequest;
};

export type QueryPartnerPartTypeReadAccessByIdArgs = {
	partnerId: Scalars['String'];
};

export type QueryProductPassTemplateArgs = {
	where?: InputMaybe<ProductPassTemplateFilter>;
};

export type ReadAccess = {
	__typename?: 'ReadAccess';
	Partner?: Maybe<Array<Maybe<Partner>>>;
	PartnerGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReadAccessRequest = {
	Partner?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	PartnerGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SimpleComponent = {
	__typename?: 'SimpleComponent';
	AssemblyLine?: Maybe<Scalars['String']>;
	ComponentId?: Maybe<Scalars['String']>;
	GBTNumber?: Maybe<Scalars['String']>;
	LastModified?: Maybe<Scalars['AWSDateTime']>;
	Lifecycle?: Maybe<Scalars['String']>;
	ManufactureDate?: Maybe<Scalars['AWSDateTime']>;
	PartNumber?: Maybe<Scalars['String']>;
	PartType?: Maybe<Scalars['String']>;
	Plant?: Maybe<Scalars['String']>;
	RegistrationDate?: Maybe<Scalars['AWSDateTime']>;
	SerialNumber?: Maybe<Scalars['String']>;
	State?: Maybe<Scalars['String']>;
	Station?: Maybe<Scalars['String']>;
	Supplier?: Maybe<Scalars['String']>;
};

export type StringFilter = {
	_begins_with?: InputMaybe<Scalars['String']>;
	_between?: InputMaybe<BetweenFilter>;
	_contains?: InputMaybe<Scalars['String']>;
	_eq?: InputMaybe<Scalars['String']>;
};

export type Subcomponent = {
	__typename?: 'Subcomponent';
	AssemblyGroup?: Maybe<AssemblyGroup>;
	GbtNumber?: Maybe<Scalars['String']>;
	PartNumber?: Maybe<Scalars['String']>;
	PartType?: Maybe<Scalars['String']>;
	Position?: Maybe<Scalars['String']>;
	ProductionDate?: Maybe<Scalars['String']>;
	SerialNumber?: Maybe<Scalars['String']>;
	SubcomponentId?: Maybe<Scalars['String']>;
	Supplier?: Maybe<Scalars['String']>;
	isBlocked?: Maybe<Scalars['Boolean']>;
};

export enum UpdateComponentLifecycle {
	FirstUse = 'FIRST_USE',
	Recycled = 'RECYCLED',
	Scrapped = 'SCRAPPED',
	SecondLife = 'SECOND_LIFE',
	Sold = 'SOLD',
}

export type UpdateComponentReadAccessRequest = {
	AdditionalData?: InputMaybe<ReadAccessRequest>;
	ComponentDetail?: InputMaybe<ReadAccessRequest>;
};

export type UpdateComponentWriteAccessRequest = {
	Assembler?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	PartnerGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateContractStateRequest = {
	ContractId: Scalars['String'];
	State: ContractState;
};

export type UpdateContractXsdRequest = {
	ContractId: Scalars['String'];
	XSDFileName: Scalars['String'];
	XSDSchema: Scalars['String'];
};

export type UpdatePartTypeReadAccessRequest = {
	ManuallySetPartTypeReadAccess?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	PartnerGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdatePartnerGroupRequest = {
	PartTypes: Array<InputMaybe<Scalars['String']>>;
	PartnerGroupName: Scalars['String'];
};

export type UpdatePartnerRelationRequest = {
	Customers: Array<InputMaybe<Scalars['String']>>;
	PartnerId: Scalars['String'];
};

export type UpdatePartnerRelationResponse = {
	__typename?: 'UpdatePartnerRelationResponse';
	PartnerId?: Maybe<Scalars['String']>;
	PermittedCustomers?: Maybe<Array<Maybe<PermittedCustomer>>>;
};

export type UserInfo = {
	__typename?: 'UserInfo';
	DUNS?: Maybe<Scalars['String']>;
	ProfileId?: Maybe<Scalars['String']>;
	Roles?: Maybe<Scalars['String']>;
};

export type WriteAccess = {
	__typename?: 'WriteAccess';
	Assembler?: Maybe<Array<Maybe<Partner>>>;
	PartnerGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Xml = {
	__typename?: 'XML';
	data?: Maybe<Scalars['String']>;
};

export type XmlReference = {
	__typename?: 'XMLReference';
	fileName?: Maybe<Scalars['String']>;
	key?: Maybe<Scalars['String']>;
	submissionDate?: Maybe<Scalars['String']>;
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
	resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
	| ResolverFn<TResult, TParent, TContext, TArgs>
	| ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
	subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
	resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
	subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
	resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
	| SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
	| SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
	| ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
	| SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
	parent: TParent,
	context: TContext,
	info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
	next: NextResolverFn<TResult>,
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
	AWSDateTime: ResolverTypeWrapper<Scalars['AWSDateTime']>;
	AWSJSON: ResolverTypeWrapper<Scalars['AWSJSON']>;
	AssemblyGroup: ResolverTypeWrapper<AssemblyGroup>;
	AvailablePartnerResponse: ResolverTypeWrapper<AvailablePartnerResponse>;
	BetweenFilter: BetweenFilter;
	Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
	CompanyInfo: ResolverTypeWrapper<CompanyInfo>;
	ComplianceContent: ResolverTypeWrapper<ComplianceContent>;
	Component: ResolverTypeWrapper<Component>;
	ComponentFilter: ComponentFilter;
	ComponentHistory: ResolverTypeWrapper<ComponentHistory>;
	ComponentId: ComponentId;
	ComponentLifecycle: ComponentLifecycle;
	ComponentLinkRequest: ComponentLinkRequest;
	ComponentList: ResolverTypeWrapper<ComponentList>;
	ComponentListCsv: ResolverTypeWrapper<ComponentListCsv>;
	ComponentReadAccess: ResolverTypeWrapper<ComponentReadAccess>;
	ComponentRequest: ComponentRequest;
	ComponentResponse: ResolverTypeWrapper<ComponentResponse>;
	ComponentState: ResolverTypeWrapper<ComponentState>;
	ComponentStateFilter: ComponentStateFilter;
	ComponentStateInput: ComponentStateInput;
	ComponentTimeRangeProperty: ComponentTimeRangeProperty;
	ComponentWriteAccess: ResolverTypeWrapper<ComponentWriteAccess>;
	ComponentXml: ResolverTypeWrapper<ComponentXml>;
	Contract: ResolverTypeWrapper<Contract>;
	ContractFullDetails: ResolverTypeWrapper<ContractFullDetails>;
	ContractHistory: ResolverTypeWrapper<ContractHistory>;
	ContractId: ResolverTypeWrapper<ContractId>;
	ContractRequest: ContractRequest;
	ContractState: ContractState;
	ContractStateResponse: ResolverTypeWrapper<ContractStateResponse>;
	ContractXsd: ResolverTypeWrapper<ContractXsd>;
	CreatePartnerGroupRequest: CreatePartnerGroupRequest;
	CreatePartnerRequest: CreatePartnerRequest;
	CreatePartnerResponse: ResolverTypeWrapper<CreatePartnerResponse>;
	Criteria: Criteria;
	Data: ResolverTypeWrapper<Data>;
	DeleteContractRequest: DeleteContractRequest;
	DeletePartnerGroupRequest: DeletePartnerGroupRequest;
	DeletePartnerRequest: DeletePartnerRequest;
	DeletePartnerResponse: ResolverTypeWrapper<DeletePartnerResponse>;
	GetCompanyDataRequest: GetCompanyDataRequest;
	GetContracts: ResolverTypeWrapper<GetContracts>;
	GetContractsByPartnerIdRequest: GetContractsByPartnerIdRequest;
	GetContractsByPartnerIdResponse: ResolverTypeWrapper<GetContractsByPartnerIdResponse>;
	GetUserDataRequest: GetUserDataRequest;
	ID: ResolverTypeWrapper<Scalars['ID']>;
	Int: ResolverTypeWrapper<Scalars['Int']>;
	LifecycleFilter: LifecycleFilter;
	Mutation: ResolverTypeWrapper<{}>;
	PartType: ResolverTypeWrapper<PartType>;
	PartTypeReadAccess: ResolverTypeWrapper<PartTypeReadAccess>;
	PartTypeRequest: PartTypeRequest;
	Partner: ResolverTypeWrapper<Partner>;
	PartnerGroup: ResolverTypeWrapper<PartnerGroup>;
	PartnerPartTypeReadAccess: ResolverTypeWrapper<PartnerPartTypeReadAccess>;
	PartnerStatus: PartnerStatus;
	PartnerType: PartnerType;
	PermittedCustomer: ResolverTypeWrapper<PermittedCustomer>;
	ProductPass: ResolverTypeWrapper<ProductPass>;
	ProductPassState: ProductPassState;
	ProductPassTemplate: ResolverTypeWrapper<ProductPassTemplate>;
	ProductPassTemplateFilter: ProductPassTemplateFilter;
	ProductPassTemplateId: ResolverTypeWrapper<ProductPassTemplateId>;
	ProductPassTemplateList: ResolverTypeWrapper<ProductPassTemplateList>;
	ProductPassTemplateState: ProductPassTemplateState;
	ProductPassTemplateStateFilter: ProductPassTemplateStateFilter;
	Query: ResolverTypeWrapper<{}>;
	ReadAccess: ResolverTypeWrapper<ReadAccess>;
	ReadAccessRequest: ReadAccessRequest;
	SimpleComponent: ResolverTypeWrapper<SimpleComponent>;
	String: ResolverTypeWrapper<Scalars['String']>;
	StringFilter: StringFilter;
	Subcomponent: ResolverTypeWrapper<Subcomponent>;
	UpdateComponentLifecycle: UpdateComponentLifecycle;
	UpdateComponentReadAccessRequest: UpdateComponentReadAccessRequest;
	UpdateComponentWriteAccessRequest: UpdateComponentWriteAccessRequest;
	UpdateContractStateRequest: UpdateContractStateRequest;
	UpdateContractXsdRequest: UpdateContractXsdRequest;
	UpdatePartTypeReadAccessRequest: UpdatePartTypeReadAccessRequest;
	UpdatePartnerGroupRequest: UpdatePartnerGroupRequest;
	UpdatePartnerRelationRequest: UpdatePartnerRelationRequest;
	UpdatePartnerRelationResponse: ResolverTypeWrapper<UpdatePartnerRelationResponse>;
	UserInfo: ResolverTypeWrapper<UserInfo>;
	WriteAccess: ResolverTypeWrapper<WriteAccess>;
	XML: ResolverTypeWrapper<Xml>;
	XMLReference: ResolverTypeWrapper<XmlReference>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
	AWSDateTime: Scalars['AWSDateTime'];
	AWSJSON: Scalars['AWSJSON'];
	AssemblyGroup: AssemblyGroup;
	AvailablePartnerResponse: AvailablePartnerResponse;
	BetweenFilter: BetweenFilter;
	Boolean: Scalars['Boolean'];
	CompanyInfo: CompanyInfo;
	ComplianceContent: ComplianceContent;
	Component: Component;
	ComponentFilter: ComponentFilter;
	ComponentHistory: ComponentHistory;
	ComponentId: ComponentId;
	ComponentLinkRequest: ComponentLinkRequest;
	ComponentList: ComponentList;
	ComponentListCsv: ComponentListCsv;
	ComponentReadAccess: ComponentReadAccess;
	ComponentRequest: ComponentRequest;
	ComponentResponse: ComponentResponse;
	ComponentState: ComponentState;
	ComponentStateFilter: ComponentStateFilter;
	ComponentStateInput: ComponentStateInput;
	ComponentWriteAccess: ComponentWriteAccess;
	ComponentXml: ComponentXml;
	Contract: Contract;
	ContractFullDetails: ContractFullDetails;
	ContractHistory: ContractHistory;
	ContractId: ContractId;
	ContractRequest: ContractRequest;
	ContractStateResponse: ContractStateResponse;
	ContractXsd: ContractXsd;
	CreatePartnerGroupRequest: CreatePartnerGroupRequest;
	CreatePartnerRequest: CreatePartnerRequest;
	CreatePartnerResponse: CreatePartnerResponse;
	Data: Data;
	DeleteContractRequest: DeleteContractRequest;
	DeletePartnerGroupRequest: DeletePartnerGroupRequest;
	DeletePartnerRequest: DeletePartnerRequest;
	DeletePartnerResponse: DeletePartnerResponse;
	GetCompanyDataRequest: GetCompanyDataRequest;
	GetContracts: GetContracts;
	GetContractsByPartnerIdRequest: GetContractsByPartnerIdRequest;
	GetContractsByPartnerIdResponse: GetContractsByPartnerIdResponse;
	GetUserDataRequest: GetUserDataRequest;
	ID: Scalars['ID'];
	Int: Scalars['Int'];
	LifecycleFilter: LifecycleFilter;
	Mutation: {};
	PartType: PartType;
	PartTypeReadAccess: PartTypeReadAccess;
	PartTypeRequest: PartTypeRequest;
	Partner: Partner;
	PartnerGroup: PartnerGroup;
	PartnerPartTypeReadAccess: PartnerPartTypeReadAccess;
	PermittedCustomer: PermittedCustomer;
	ProductPass: ProductPass;
	ProductPassTemplate: ProductPassTemplate;
	ProductPassTemplateFilter: ProductPassTemplateFilter;
	ProductPassTemplateId: ProductPassTemplateId;
	ProductPassTemplateList: ProductPassTemplateList;
	ProductPassTemplateStateFilter: ProductPassTemplateStateFilter;
	Query: {};
	ReadAccess: ReadAccess;
	ReadAccessRequest: ReadAccessRequest;
	SimpleComponent: SimpleComponent;
	String: Scalars['String'];
	StringFilter: StringFilter;
	Subcomponent: Subcomponent;
	UpdateComponentReadAccessRequest: UpdateComponentReadAccessRequest;
	UpdateComponentWriteAccessRequest: UpdateComponentWriteAccessRequest;
	UpdateContractStateRequest: UpdateContractStateRequest;
	UpdateContractXsdRequest: UpdateContractXsdRequest;
	UpdatePartTypeReadAccessRequest: UpdatePartTypeReadAccessRequest;
	UpdatePartnerGroupRequest: UpdatePartnerGroupRequest;
	UpdatePartnerRelationRequest: UpdatePartnerRelationRequest;
	UpdatePartnerRelationResponse: UpdatePartnerRelationResponse;
	UserInfo: UserInfo;
	WriteAccess: WriteAccess;
	XML: Xml;
	XMLReference: XmlReference;
};

export interface AwsDateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSDateTime'], any> {
	name: 'AWSDateTime';
}

export interface AwsjsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSJSON'], any> {
	name: 'AWSJSON';
}

export type AssemblyGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssemblyGroup'] = ResolversParentTypes['AssemblyGroup']> = {
	ContainerGBT?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ContainerVW?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ParentPosition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvailablePartnerResponseResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['AvailablePartnerResponse'] = ResolversParentTypes['AvailablePartnerResponse'],
> = {
	PartnerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerStatus?: Resolver<Maybe<ResolversTypes['PartnerStatus']>, ParentType, ContextType>;
	PartnerType?: Resolver<Maybe<ResolversTypes['PartnerType']>, ParentType, ContextType>;
	PermittedCustomers?: Resolver<Maybe<Array<Maybe<ResolversTypes['PermittedCustomer']>>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyInfo'] = ResolversParentTypes['CompanyInfo']> = {
	DUNS?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ComplianceContentResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['ComplianceContent'] = ResolversParentTypes['ComplianceContent'],
> = {
	BusinessPartnerPolicy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	CookiePolicy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	GroupWhistleblower?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Imprint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PrivacyPolicy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	SystemHelp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	TermsOfUse?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ThirdPartyLicenses?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	VwBrandEmployeePolicy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	VwGroupEmployeePolicy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ComponentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Component'] = ResolversParentTypes['Component']> = {
	AssemblerPartner?: Resolver<Maybe<ResolversTypes['Partner']>, ParentType, ContextType>;
	AssemblerPartnerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	AssemblyGroup?: Resolver<Maybe<ResolversTypes['AssemblyGroup']>, ParentType, ContextType>;
	AssemblyLine?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ComponentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ContractId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	CreationDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
	CurrentAttachmentsReferences?: Resolver<Maybe<Array<Maybe<ResolversTypes['XMLReference']>>>, ParentType, ContextType>;
	CurrentXmlReference?: Resolver<Maybe<ResolversTypes['XMLReference']>, ParentType, ContextType>;
	DeletionDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	GBTNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	IsLotPart?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
	KsuClass?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	KsuTTL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	LastModified?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
	ManufactureDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
	ManufacturerPartner?: Resolver<Maybe<ResolversTypes['Partner']>, ParentType, ContextType>;
	ManufacturerPartnerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ParentComponentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Plant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	RegistrationDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
	SerialNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Station?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Status?: Resolver<Maybe<ResolversTypes['ComponentState']>, ParentType, ContextType>;
	SubComponents?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ComponentHistoryResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['ComponentHistory'] = ResolversParentTypes['ComponentHistory'],
> = {
	ChangeComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ChangeDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	FileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	VersionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ComponentListResolvers<ContextType = any, ParentType extends ResolversParentTypes['ComponentList'] = ResolversParentTypes['ComponentList']> = {
	items?: Resolver<Maybe<Array<Maybe<ResolversTypes['SimpleComponent']>>>, ParentType, ContextType>;
	itemsLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
	limitExceeded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
	notFound?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ComponentListCsvResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['ComponentListCsv'] = ResolversParentTypes['ComponentListCsv'],
> = {
	csvURI?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	notFound?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ComponentReadAccessResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['ComponentReadAccess'] = ResolversParentTypes['ComponentReadAccess'],
> = {
	AdditionalData?: Resolver<Maybe<ResolversTypes['ReadAccess']>, ParentType, ContextType>;
	ComponentDetail?: Resolver<Maybe<ResolversTypes['ReadAccess']>, ParentType, ContextType>;
	ContractId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Partner?: Resolver<Maybe<ResolversTypes['Partner']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ComponentResponseResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['ComponentResponse'] = ResolversParentTypes['ComponentResponse'],
> = {
	ComponentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ContractId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ComponentStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ComponentState'] = ResolversParentTypes['ComponentState']> = {
	isBlocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
	isBlockedBySubcomponent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
	isComplete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
	isLinkedToParent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
	lifecycle?: Resolver<Maybe<ResolversTypes['ComponentLifecycle']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ComponentWriteAccessResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['ComponentWriteAccess'] = ResolversParentTypes['ComponentWriteAccess'],
> = {
	Assembler?: Resolver<Maybe<Array<Maybe<ResolversTypes['Partner']>>>, ParentType, ContextType>;
	ComponentWriteAccessByContract?: Resolver<Maybe<ResolversTypes['WriteAccess']>, ParentType, ContextType>;
	ContractId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Partner?: Resolver<Maybe<ResolversTypes['Partner']>, ParentType, ContextType>;
	PartnerGroup?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ComponentXmlResolvers<ContextType = any, ParentType extends ResolversParentTypes['ComponentXml'] = ResolversParentTypes['ComponentXml']> = {
	XmlUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractResolvers<ContextType = any, ParentType extends ResolversParentTypes['Contract'] = ResolversParentTypes['Contract']> = {
	Approver?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
	ContractId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	Creator?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
	Customer?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
	KsuClass?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	KsuTTL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	LastModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	State?: Resolver<Maybe<ResolversTypes['ContractState']>, ParentType, ContextType>;
	Supplier?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
	TransactionHistory?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractFullDetailsResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['ContractFullDetails'] = ResolversParentTypes['ContractFullDetails'],
> = {
	ApproverPartner?: Resolver<Maybe<ResolversTypes['Partner']>, ParentType, ContextType>;
	ApproverPartnerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
	BlobDefinition?: Resolver<Maybe<Array<Maybe<ResolversTypes['AWSJSON']>>>, ParentType, ContextType>;
	ContractId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	CreatorPartner?: Resolver<Maybe<ResolversTypes['Partner']>, ParentType, ContextType>;
	CreatorPartnerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
	CustomerPartner?: Resolver<Maybe<ResolversTypes['Partner']>, ParentType, ContextType>;
	CustomerPartnerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
	KsuClass?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	KsuTTL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	LastModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	State?: Resolver<Maybe<ResolversTypes['ContractState']>, ParentType, ContextType>;
	SupplierPartner?: Resolver<Maybe<ResolversTypes['Partner']>, ParentType, ContextType>;
	SupplierPartnerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
	TransactionHistory?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	XSDSchemaReference?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractHistoryResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['ContractHistory'] = ResolversParentTypes['ContractHistory'],
> = {
	ChangeComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ChangeDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractIdResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractId'] = ResolversParentTypes['ContractId']> = {
	ContractId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractStateResponseResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['ContractStateResponse'] = ResolversParentTypes['ContractStateResponse'],
> = {
	ContractId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	State?: Resolver<Maybe<ResolversTypes['ContractState']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractXsdResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractXsd'] = ResolversParentTypes['ContractXsd']> = {
	XsdUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreatePartnerResponseResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['CreatePartnerResponse'] = ResolversParentTypes['CreatePartnerResponse'],
> = {
	PartnerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerType?: Resolver<Maybe<ResolversTypes['PartnerType']>, ParentType, ContextType>;
	PermittedCustomers?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataResolvers<ContextType = any, ParentType extends ResolversParentTypes['Data'] = ResolversParentTypes['Data']> = {
	key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeletePartnerResponseResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['DeletePartnerResponse'] = ResolversParentTypes['DeletePartnerResponse'],
> = {
	PartnerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetContractsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetContracts'] = ResolversParentTypes['GetContracts']> = {
	ContractId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetContractsByPartnerIdResponseResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['GetContractsByPartnerIdResponse'] = ResolversParentTypes['GetContractsByPartnerIdResponse'],
> = {
	Approver?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ContractId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Creator?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	LastModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	State?: Resolver<Maybe<ResolversTypes['ContractState']>, ParentType, ContextType>;
	Supplier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
	blockComponent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationBlockComponentArgs>>;
	createPartType?: Resolver<Maybe<ResolversTypes['PartType']>, ParentType, ContextType, RequireFields<MutationCreatePartTypeArgs, 'input'>>;
	createPartner?: Resolver<Maybe<ResolversTypes['CreatePartnerResponse']>, ParentType, ContextType, RequireFields<MutationCreatePartnerArgs, 'input'>>;
	createPartnerGroup?: Resolver<Maybe<ResolversTypes['PartnerGroup']>, ParentType, ContextType, RequireFields<MutationCreatePartnerGroupArgs, 'input'>>;
	createProductPassTemplate?: Resolver<
		Maybe<ResolversTypes['ProductPassTemplateId']>,
		ParentType,
		ContextType,
		RequireFields<MutationCreateProductPassTemplateArgs, 'jsonFileBase64Encoded' | 'law'>
	>;
	deleteContract?: Resolver<Maybe<ResolversTypes['ContractId']>, ParentType, ContextType, RequireFields<MutationDeleteContractArgs, 'input'>>;
	deletePartType?: Resolver<Maybe<ResolversTypes['PartType']>, ParentType, ContextType, RequireFields<MutationDeletePartTypeArgs, 'input'>>;
	deletePartner?: Resolver<Maybe<ResolversTypes['DeletePartnerResponse']>, ParentType, ContextType, RequireFields<MutationDeletePartnerArgs, 'input'>>;
	deletePartnerGroup?: Resolver<Maybe<ResolversTypes['PartnerGroup']>, ParentType, ContextType, RequireFields<MutationDeletePartnerGroupArgs, 'input'>>;
	linkComponent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationLinkComponentArgs>>;
	registerComponent?: Resolver<Maybe<ResolversTypes['ComponentResponse']>, ParentType, ContextType, Partial<MutationRegisterComponentArgs>>;
	registerContract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType, RequireFields<MutationRegisterContractArgs, 'input'>>;
	unblockComponent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationUnblockComponentArgs>>;
	unlinkComponent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationUnlinkComponentArgs>>;
	updateComponent?: Resolver<Maybe<ResolversTypes['ComponentResponse']>, ParentType, ContextType, Partial<MutationUpdateComponentArgs>>;
	updateComponentLifecycle?: Resolver<
		Maybe<ResolversTypes['ComponentResponse']>,
		ParentType,
		ContextType,
		RequireFields<MutationUpdateComponentLifecycleArgs, 'componentId' | 'lifecycle'>
	>;
	updateComponentReadAccess?: Resolver<
		Maybe<ResolversTypes['ComponentReadAccess']>,
		ParentType,
		ContextType,
		RequireFields<MutationUpdateComponentReadAccessArgs, 'contractId' | 'input'>
	>;
	updateComponentWriteAccess?: Resolver<
		Maybe<ResolversTypes['ComponentWriteAccess']>,
		ParentType,
		ContextType,
		RequireFields<MutationUpdateComponentWriteAccessArgs, 'contractId' | 'input'>
	>;
	updateContractState?: Resolver<
		Maybe<ResolversTypes['ContractStateResponse']>,
		ParentType,
		ContextType,
		RequireFields<MutationUpdateContractStateArgs, 'input'>
	>;
	updateContractXSD?: Resolver<Maybe<ResolversTypes['ContractFullDetails']>, ParentType, ContextType, RequireFields<MutationUpdateContractXsdArgs, 'input'>>;
	updatePartnerGroup?: Resolver<Maybe<ResolversTypes['PartnerGroup']>, ParentType, ContextType, RequireFields<MutationUpdatePartnerGroupArgs, 'input'>>;
	updatePartnerPartTypeReadAccess?: Resolver<
		Maybe<ResolversTypes['PartnerPartTypeReadAccess']>,
		ParentType,
		ContextType,
		RequireFields<MutationUpdatePartnerPartTypeReadAccessArgs, 'input' | 'partnerId'>
	>;
	updatePartnerRelation?: Resolver<
		Maybe<ResolversTypes['UpdatePartnerRelationResponse']>,
		ParentType,
		ContextType,
		RequireFields<MutationUpdatePartnerRelationArgs, 'input'>
	>;
};

export type PartTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PartType'] = ResolversParentTypes['PartType']> = {
	InUse?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
	PartTypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	RegistrationDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
	SubPartTypeSet?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartTypeReadAccessResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['PartTypeReadAccess'] = ResolversParentTypes['PartTypeReadAccess'],
> = {
	AutomaticallySet?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	ManuallySet?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Partner'] = ResolversParentTypes['Partner']> = {
	PartnerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerStatus?: Resolver<Maybe<ResolversTypes['PartnerStatus']>, ParentType, ContextType>;
	PartnerType?: Resolver<Maybe<ResolversTypes['PartnerType']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['PartnerGroup'] = ResolversParentTypes['PartnerGroup']> = {
	PartTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	PartnerGroupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerPartTypeReadAccessResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['PartnerPartTypeReadAccess'] = ResolversParentTypes['PartnerPartTypeReadAccess'],
> = {
	PartTypeReadAccess?: Resolver<Maybe<ResolversTypes['PartTypeReadAccess']>, ParentType, ContextType>;
	PartnerGroup?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	PartnerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerStatus?: Resolver<Maybe<ResolversTypes['PartnerStatus']>, ParentType, ContextType>;
	PartnerType?: Resolver<Maybe<ResolversTypes['PartnerType']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermittedCustomerResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['PermittedCustomer'] = ResolversParentTypes['PermittedCustomer'],
> = {
	PartnerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartnerStatus?: Resolver<Maybe<ResolversTypes['PartnerStatus']>, ParentType, ContextType>;
	PartnerType?: Resolver<Maybe<ResolversTypes['PartnerType']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPassResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductPass'] = ResolversParentTypes['ProductPass']> = {
	Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	LastModified?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
	Name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ProductPassId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	State?: Resolver<Maybe<ResolversTypes['ProductPassState']>, ParentType, ContextType>;
	Template?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPassTemplateResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['ProductPassTemplate'] = ResolversParentTypes['ProductPassTemplate'],
> = {
	Description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	LastModified?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
	Law?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ProductPassTemplateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ProductPasses?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductPass']>>>, ParentType, ContextType>;
	State?: Resolver<Maybe<ResolversTypes['ProductPassTemplateState']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPassTemplateIdResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['ProductPassTemplateId'] = ResolversParentTypes['ProductPassTemplateId'],
> = {
	templateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductPassTemplateListResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['ProductPassTemplateList'] = ResolversParentTypes['ProductPassTemplateList'],
> = {
	items?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductPassTemplate']>>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
	componentHistoryById?: Resolver<
		Maybe<Array<Maybe<ResolversTypes['ComponentHistory']>>>,
		ParentType,
		ContextType,
		RequireFields<QueryComponentHistoryByIdArgs, 'componentId'>
	>;
	componentReadAccess?: Resolver<Maybe<Array<Maybe<ResolversTypes['ComponentReadAccess']>>>, ParentType, ContextType>;
	componentReadAccessById?: Resolver<
		Maybe<ResolversTypes['ComponentReadAccess']>,
		ParentType,
		ContextType,
		RequireFields<QueryComponentReadAccessByIdArgs, 'contractId'>
	>;
	componentWriteAccess?: Resolver<Maybe<Array<Maybe<ResolversTypes['ComponentWriteAccess']>>>, ParentType, ContextType>;
	componentWriteAccessById?: Resolver<
		Maybe<ResolversTypes['ComponentWriteAccess']>,
		ParentType,
		ContextType,
		RequireFields<QueryComponentWriteAccessByIdArgs, 'contractId'>
	>;
	componentsByContractId?: Resolver<
		Maybe<ResolversTypes['ComponentList']>,
		ParentType,
		ContextType,
		RequireFields<QueryComponentsByContractIdArgs, 'contractId' | 'where'>
	>;
	componentsByOtherCriteria?: Resolver<
		Maybe<ResolversTypes['ComponentList']>,
		ParentType,
		ContextType,
		RequireFields<QueryComponentsByOtherCriteriaArgs, 'criteriaType' | 'criteriaValue'>
	>;
	componentsCsvByContractId?: Resolver<
		Maybe<ResolversTypes['ComponentListCsv']>,
		ParentType,
		ContextType,
		RequireFields<QueryComponentsCsvByContractIdArgs, 'contractId' | 'where'>
	>;
	componentsCsvByOtherCriteria?: Resolver<
		Maybe<ResolversTypes['ComponentListCsv']>,
		ParentType,
		ContextType,
		RequireFields<QueryComponentsCsvByOtherCriteriaArgs, 'criteriaType' | 'criteriaValue'>
	>;
	contractByPermission?: Resolver<Maybe<Array<Maybe<ResolversTypes['Contract']>>>, ParentType, ContextType>;
	contractHistoryById?: Resolver<
		Maybe<Array<Maybe<ResolversTypes['ContractHistory']>>>,
		ParentType,
		ContextType,
		RequireFields<QueryContractHistoryByIdArgs, 'contractId'>
	>;
	exportComponentXmlById?: Resolver<
		Maybe<ResolversTypes['ComponentXml']>,
		ParentType,
		ContextType,
		RequireFields<QueryExportComponentXmlByIdArgs, 'ComponentId'>
	>;
	exportContractXsdById?: Resolver<
		Maybe<ResolversTypes['ContractXsd']>,
		ParentType,
		ContextType,
		RequireFields<QueryExportContractXsdByIdArgs, 'ContractId'>
	>;
	getAvailableCustomersBySupplierId?: Resolver<
		Maybe<Array<Maybe<ResolversTypes['AvailablePartnerResponse']>>>,
		ParentType,
		ContextType,
		RequireFields<QueryGetAvailableCustomersBySupplierIdArgs, 'PartnerId'>
	>;
	getAvailablePartner?: Resolver<Maybe<Array<Maybe<ResolversTypes['AvailablePartnerResponse']>>>, ParentType, ContextType>;
	getAvailableSuppliers?: Resolver<Maybe<Array<Maybe<ResolversTypes['AvailablePartnerResponse']>>>, ParentType, ContextType>;
	getCompanyData?: Resolver<Maybe<ResolversTypes['CompanyInfo']>, ParentType, ContextType, Partial<QueryGetCompanyDataArgs>>;
	getComplianceContent?: Resolver<Maybe<ResolversTypes['ComplianceContent']>, ParentType, ContextType>;
	getComponentAdditionalData?: Resolver<
		Maybe<Array<Maybe<ResolversTypes['Data']>>>,
		ParentType,
		ContextType,
		RequireFields<QueryGetComponentAdditionalDataArgs, 'input'>
	>;
	getComponentById?: Resolver<Maybe<ResolversTypes['Component']>, ParentType, ContextType, RequireFields<QueryGetComponentByIdArgs, 'ComponentId'>>;
	getComponentDetails?: Resolver<Maybe<ResolversTypes['XML']>, ParentType, ContextType, RequireFields<QueryGetComponentDetailsArgs, 'input'>>;
	getComponentSubcomponents?: Resolver<
		Maybe<Array<Maybe<ResolversTypes['Subcomponent']>>>,
		ParentType,
		ContextType,
		RequireFields<QueryGetComponentSubcomponentsArgs, 'input'>
	>;
	getContractFullDetailsById?: Resolver<
		Maybe<ResolversTypes['ContractFullDetails']>,
		ParentType,
		ContextType,
		RequireFields<QueryGetContractFullDetailsByIdArgs, 'ContractId'>
	>;
	getContractsByPartnerId?: Resolver<
		Maybe<Array<Maybe<ResolversTypes['GetContractsByPartnerIdResponse']>>>,
		ParentType,
		ContextType,
		Partial<QueryGetContractsByPartnerIdArgs>
	>;
	getPartTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['PartType']>>>, ParentType, ContextType>;
	getPartnerGroupByName?: Resolver<
		Maybe<ResolversTypes['PartnerGroup']>,
		ParentType,
		ContextType,
		RequireFields<QueryGetPartnerGroupByNameArgs, 'PartnerGroupName'>
	>;
	getPartnerGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['PartnerGroup']>>>, ParentType, ContextType>;
	getUserData?: Resolver<Maybe<ResolversTypes['UserInfo']>, ParentType, ContextType, RequireFields<QueryGetUserDataArgs, 'input'>>;
	partnerPartTypeReadAccess?: Resolver<Maybe<Array<Maybe<ResolversTypes['PartnerPartTypeReadAccess']>>>, ParentType, ContextType>;
	partnerPartTypeReadAccessById?: Resolver<
		Maybe<ResolversTypes['PartnerPartTypeReadAccess']>,
		ParentType,
		ContextType,
		RequireFields<QueryPartnerPartTypeReadAccessByIdArgs, 'partnerId'>
	>;
	productPassTemplate?: Resolver<Maybe<ResolversTypes['ProductPassTemplateList']>, ParentType, ContextType, Partial<QueryProductPassTemplateArgs>>;
};

export type ReadAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReadAccess'] = ResolversParentTypes['ReadAccess']> = {
	Partner?: Resolver<Maybe<Array<Maybe<ResolversTypes['Partner']>>>, ParentType, ContextType>;
	PartnerGroup?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SimpleComponentResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['SimpleComponent'] = ResolversParentTypes['SimpleComponent'],
> = {
	AssemblyLine?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ComponentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	GBTNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	LastModified?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
	Lifecycle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ManufactureDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
	PartNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Plant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	RegistrationDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
	SerialNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	State?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Station?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Supplier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubcomponentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subcomponent'] = ResolversParentTypes['Subcomponent']> = {
	AssemblyGroup?: Resolver<Maybe<ResolversTypes['AssemblyGroup']>, ParentType, ContextType>;
	GbtNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PartType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ProductionDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	SerialNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	SubcomponentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Supplier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	isBlocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdatePartnerRelationResponseResolvers<
	ContextType = any,
	ParentType extends ResolversParentTypes['UpdatePartnerRelationResponse'] = ResolversParentTypes['UpdatePartnerRelationResponse'],
> = {
	PartnerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	PermittedCustomers?: Resolver<Maybe<Array<Maybe<ResolversTypes['PermittedCustomer']>>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserInfo'] = ResolversParentTypes['UserInfo']> = {
	DUNS?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	ProfileId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	Roles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WriteAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['WriteAccess'] = ResolversParentTypes['WriteAccess']> = {
	Assembler?: Resolver<Maybe<Array<Maybe<ResolversTypes['Partner']>>>, ParentType, ContextType>;
	PartnerGroup?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type XmlResolvers<ContextType = any, ParentType extends ResolversParentTypes['XML'] = ResolversParentTypes['XML']> = {
	data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type XmlReferenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['XMLReference'] = ResolversParentTypes['XMLReference']> = {
	fileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	submissionDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
	AWSDateTime?: GraphQLScalarType;
	AWSJSON?: GraphQLScalarType;
	AssemblyGroup?: AssemblyGroupResolvers<ContextType>;
	AvailablePartnerResponse?: AvailablePartnerResponseResolvers<ContextType>;
	CompanyInfo?: CompanyInfoResolvers<ContextType>;
	ComplianceContent?: ComplianceContentResolvers<ContextType>;
	Component?: ComponentResolvers<ContextType>;
	ComponentHistory?: ComponentHistoryResolvers<ContextType>;
	ComponentList?: ComponentListResolvers<ContextType>;
	ComponentListCsv?: ComponentListCsvResolvers<ContextType>;
	ComponentReadAccess?: ComponentReadAccessResolvers<ContextType>;
	ComponentResponse?: ComponentResponseResolvers<ContextType>;
	ComponentState?: ComponentStateResolvers<ContextType>;
	ComponentWriteAccess?: ComponentWriteAccessResolvers<ContextType>;
	ComponentXml?: ComponentXmlResolvers<ContextType>;
	Contract?: ContractResolvers<ContextType>;
	ContractFullDetails?: ContractFullDetailsResolvers<ContextType>;
	ContractHistory?: ContractHistoryResolvers<ContextType>;
	ContractId?: ContractIdResolvers<ContextType>;
	ContractStateResponse?: ContractStateResponseResolvers<ContextType>;
	ContractXsd?: ContractXsdResolvers<ContextType>;
	CreatePartnerResponse?: CreatePartnerResponseResolvers<ContextType>;
	Data?: DataResolvers<ContextType>;
	DeletePartnerResponse?: DeletePartnerResponseResolvers<ContextType>;
	GetContracts?: GetContractsResolvers<ContextType>;
	GetContractsByPartnerIdResponse?: GetContractsByPartnerIdResponseResolvers<ContextType>;
	Mutation?: MutationResolvers<ContextType>;
	PartType?: PartTypeResolvers<ContextType>;
	PartTypeReadAccess?: PartTypeReadAccessResolvers<ContextType>;
	Partner?: PartnerResolvers<ContextType>;
	PartnerGroup?: PartnerGroupResolvers<ContextType>;
	PartnerPartTypeReadAccess?: PartnerPartTypeReadAccessResolvers<ContextType>;
	PermittedCustomer?: PermittedCustomerResolvers<ContextType>;
	ProductPass?: ProductPassResolvers<ContextType>;
	ProductPassTemplate?: ProductPassTemplateResolvers<ContextType>;
	ProductPassTemplateId?: ProductPassTemplateIdResolvers<ContextType>;
	ProductPassTemplateList?: ProductPassTemplateListResolvers<ContextType>;
	Query?: QueryResolvers<ContextType>;
	ReadAccess?: ReadAccessResolvers<ContextType>;
	SimpleComponent?: SimpleComponentResolvers<ContextType>;
	Subcomponent?: SubcomponentResolvers<ContextType>;
	UpdatePartnerRelationResponse?: UpdatePartnerRelationResponseResolvers<ContextType>;
	UserInfo?: UserInfoResolvers<ContextType>;
	WriteAccess?: WriteAccessResolvers<ContextType>;
	XML?: XmlResolvers<ContextType>;
	XMLReference?: XmlReferenceResolvers<ContextType>;
};

/**
 * @description Used to avoid magic strings when defining url params
 */
export const enum PARAM_NAME {
	COMPONENT_ID = 'componentId',
	CONTRACT_ID = 'contractId',
	PRODUCT_PASS_ID = 'productPassId',
	PRODUCT_PASS_TEMPLATE_ID = 'productPassTemplateId',
}

export enum PagePaths {
	// HOME
	HOME = '/',
	// CONTRACT
	CONTRACT_MANAGEMENT_ALL_CONTRACTS_BY_PARTNER_ID = '/contract',
	CONTRACT_MANAGEMENT_NEW = '/contract/new',
	CONTRACT_MANAGEMENT_DETAIL = `/contract`,
	CONTRACT_MANAGEMENT_COMPONENT_READ_PERMISSION = '/contract/permission/read/component',
	CONTRACT_MANAGEMENT_COMPONENT_WRITE_PERMISSION = '/contract/permission/write/component',
	// COMPONENT
	COMPONENT_MANAGEMENT_ALL_COMPONENTS_BY_CONTRACT_ID = '/component',
	COMPONENT_MANAGEMENT_NEW = '/component/new',
	COMPONENT_MANAGEMENT_UPDATE = '/component/update',
	COMPONENT_MANAGEMENT_DETAIL = '/component',

	// PART TYPE MANAGEMENT
	PART_TYPE_MANAGEMENT_ALL_PART_TYPES = '/part-type',
	PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER = '/part-type/permission/read/partner',
	PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER_GROUP = '/part-type/permission/read/partner-group',
	// PARTNER MANAGEMENT
	PARTNER_MANAGEMENT_ALL_PARTNERS = '/partner',
	PARTNER_MANAGEMENT_MANAGE_ASSIGNED_PARTNER_GROUPS = '/partner/partner-group',
	PARTNER_MANAGEMENT_PERMITTED_PARTNER_RELATION = '/partner/partner-relation',
	// PARTNER GROUP MANAGEMENT
	PARTNER_GROUP_MANAGEMENT_ALL_PARTNER_GROUPS = '/partner-group',

	//PRODUCT PASS
	PRODUCT_PASS_ALL_PRODUCT_PASSES = '/product-pass',
	PRODUCT_PASS_NEW = '/product-pass/new',
	PRODUCT_PASS_DETAIL = '/product-pass',
	//PRODUCT PASS TEMPLATE
	PRODUCT_PASS_ALL_PRODUCT_PASS_TEMPLATES = '/product-pass-template',
	PRODUCT_PASS_TEMPLATE_NEW = '/product-pass-template/new',
	PRODUCT_PASS_TEMPLATE_DETAIL = '/product-pass-template',
}

import { AuthService } from '@core/services/auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlTree } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(private auth: AuthService) {}
	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.auth.isAuthenticated();
	}
}

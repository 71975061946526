<groupui-modal
	*transloco="let t"
	m="12"
	l="8"
	xl="6"
	xxl="4"
	class="notification-card"
	[displayed]="fieldSettings.isOpen"
	[closeButton]="fieldSettings.closeAble === undefined ? true : fieldSettings.closeAble"
	[disableBackdropClose]="true"
	[disableEscapeClose]="true"
	(groupuiModalClose)="closeModal(fieldSettings.onClose)">
	<div class="notification-color" [ngClass]="fieldSettings.type"></div>
	<groupui-headline heading="h3">{{ t($any(fieldSettings.title)) }}</groupui-headline>

	<!-- modal content -->
	<div class="notification-content">
		<groupui-text class="notification-content-text">{{ t($any(fieldSettings.contentText)) }}</groupui-text>

		<div class="copy-area" *ngIf="fieldSettings.copyContent">
			<div class="copy-title">
				<groupui-text>{{ t(fieldSettings.copyContent.copyText) }}</groupui-text>
				<groupui-button
					icon-only="true"
					variant="tertiary"
					size="s"
					icon="copy-16"
					[cdkCopyToClipboard]="fieldSettings.copyContent.copyValue"></groupui-button>
			</div>
			<groupui-text class="copy-value">{{ fieldSettings.copyContent.copyValue }}</groupui-text>
		</div>
		<!-- modal parent copy content -->
		<div class="copy-area" *ngIf="fieldSettings.parentCopyContent">
			<div class="copy-title">
				<groupui-text>{{ t(fieldSettings.parentCopyContent.copyText) }}</groupui-text>
				<groupui-button
					*ngIf="fieldSettings.parentCopyContent.copyValue !== '-' && fieldSettings.parentCopyContent.copyValue.length <= 1"
					icon-only="true"
					variant="tertiary"
					size="s"
					icon="copy-16"
					[cdkCopyToClipboard]="fieldSettings.parentCopyContent.copyValue"></groupui-button>
			</div>
			<groupui-text class="copy-value" data-test="copy-value">{{ fieldSettings.parentCopyContent.copyValue }}</groupui-text>
		</div>
		<ng-content></ng-content>
	</div>

	<!-- modal optional content -->
	<div class="notification-optional-content" *ngIf="fieldSettings.optionalMessage">
		<groupui-accordion iconPosition="left">
			<span slot="headline">
				<groupui-headline heading="h6" weight="light">Backend Message</groupui-headline>
			</span>
			{{ fieldSettings.optionalMessage }}
		</groupui-accordion>
	</div>

	<!-- modal button footer -->
	<div class="display-flex gap-groupui-spacing-3 justify-content-flex-end" *ngIf="fieldSettings?.primaryButton || fieldSettings?.secondaryButton">
		<groupui-button
			class="notification-secondary-button"
			*ngIf="fieldSettings?.secondaryButton"
			variant="secondary"
			(click)="closeModal(fieldSettings.secondaryButton?.function); secondaryClick.emit(fieldSettings.payload)"
			data-test="btn-secondary"
			[icon]="fieldSettings.secondaryButton?.icon">
			{{ t($any(fieldSettings.secondaryButton?.label)) }}
		</groupui-button>
		<groupui-button
			class="notification-primary-button"
			*ngIf="fieldSettings?.primaryButton && !fieldSettings.dangerButton"
			variant="primary"
			(click)="closeModal(fieldSettings.primaryButton?.function); primaryClick.emit(fieldSettings.payload)"
			data-test="btn-primary"
			[icon]="fieldSettings.primaryButton?.icon">
			{{ t($any(fieldSettings.primaryButton?.label)) }}
		</groupui-button>
		<groupui-button
			class="notification-primary-button"
			*ngIf="fieldSettings?.primaryButton && fieldSettings.dangerButton"
			variant="primary"
			severity="danger"
			(click)="closeModal(fieldSettings.primaryButton?.function); primaryClick.emit(fieldSettings.payload)"
			data-test="btn-primary"
			[icon]="fieldSettings.primaryButton?.icon">
			{{ t($any(fieldSettings.primaryButton?.label)) }}
		</groupui-button>
	</div>
</groupui-modal>

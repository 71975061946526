import { gql } from 'apollo-angular';

export const GQL_QUERY_PART_TYPES = gql`
	query getPartTypes {
		getPartTypes {
			PartTypeName
			RegistrationDate
			InUse
			SubPartTypeSet
		}
	}
`;

export const GQL_MUTATION_CREATE_PART_TYPE = gql`
	mutation createPartType($input: PartTypeRequest!) {
		createPartType(input: $input) {
			PartTypeName
			RegistrationDate
			InUse
		}
	}
`;

export const GQL_MUTATION_DELETE_PART_TYPE = gql`
	mutation deletePartType($input: PartTypeRequest!) {
		deletePartType(input: $input) {
			PartTypeName
		}
	}
`;

export const GQL_QUERY_PARTNER_GROUPS = gql`
	query getPartnerGroups {
		getPartnerGroups {
			PartnerGroupName
			PartTypes
		}
	}
`;

export const GQL_MUTATION_CREATE_PARTNER_GROUP = gql`
	mutation createPartnerGroup($input: CreatePartnerGroupRequest!) {
		createPartnerGroup(input: $input) {
			PartnerGroupName
			PartTypes
		}
	}
`;

export const GQL_MUTATION_DELETE_PARTNER_GROUP = gql`
	mutation deletePartnerGroup($input: DeletePartnerGroupRequest!) {
		deletePartnerGroup(input: $input) {
			PartnerGroupName
			PartTypes
		}
	}
`;

export const GQL_MUTATION_UPDATE_PARTNER_GROUP = gql`
	mutation updatePartnerGroup($input: UpdatePartnerGroupRequest!) {
		updatePartnerGroup(input: $input) {
			PartnerGroupName
			PartTypes
		}
	}
`;

export const GQL_QUERY_GET_AVAILABLE_SUPPLIERS = gql`
	query getAvailableSuppliers {
		getAvailableSuppliers {
			PartnerId
			PartnerName
			PartnerLocation
			PartnerType
			PartnerStatus
		}
	}
`;
export const GQL_QUERY_CUSTOMERS_BY_PARTNER_ID = gql`
	query getAvailableCustomersBySupplierId($partnerId: String!) {
		getAvailableCustomersBySupplierId(PartnerId: $partnerId) {
			PartnerId
			PartnerName
			PartnerLocation
			PartnerType
			PartnerStatus
		}
	}
`;

export const GQL_QUERY_GET_ALL_AVAILABLE_PARTNERS = gql`
	query getAvailablePartner($includePermittedCustomers: Boolean!) {
		getAvailablePartner {
			PartnerId
			PartnerName
			PartnerLocation
			PartnerType
			PartnerStatus
			PermittedCustomers @include(if: $includePermittedCustomers) {
				PartnerId
				PartnerName
				PartnerLocation
				PartnerType
				PartnerStatus
			}
		}
	}
`;

export const GQL_MUTATION_DELETE_PARTNER = gql`
	mutation deletePartner($partnerId: String!) {
		deletePartner(input: { PartnerId: $partnerId }) {
			PartnerId
		}
	}
`;
export const GQL_MUTATION_UPDATE_PARTNER_RELATION = gql`
	mutation updatePartnerRelation($partnerId: String!, $customers: [String]!) {
		updatePartnerRelation(input: { PartnerId: $partnerId, Customers: $customers }) {
			PartnerId
			PermittedCustomers {
				PartnerId
				PartnerName
				PartnerLocation
				PartnerType
				PartnerStatus
			}
		}
	}
`;
export const GQL_MUTATION_CREATE_PARTNER = gql`
	mutation createPartner($PartnerId: String!, $PartnerName: String, $PartnerLocation: String, $PartnerType: PartnerType) {
		createPartner(input: { PartnerId: $PartnerId, PartnerName: $PartnerName, PartnerLocation: $PartnerLocation, PartnerType: $PartnerType }) {
			PartnerId
			PartnerName
			PartnerLocation
			PartnerType
			PermittedCustomers
		}
	}
`;

export const GQL_QUERY_PARTNER_PART_TYPE_READ_ACCESS = gql`
	query partnerPartTypeReadAccess {
		partnerPartTypeReadAccess {
			PartnerId
			PartnerName
			PartnerLocation
			PartnerType
			PartnerStatus
			PartnerGroup
			PartTypeReadAccess {
				ManuallySet
				AutomaticallySet
			}
		}
	}
`;

export const GQL_MUTATION_UPDATE_PARTNER_PART_TYPE_READ_ACCESS = gql`
	mutation updatePartnerPartTypeReadAccess($partnerId: String!, $input: UpdatePartTypeReadAccessRequest!) {
		updatePartnerPartTypeReadAccess(partnerId: $partnerId, input: $input) {
			PartnerId
			PartnerName
			PartnerLocation
			PartnerType
			PartnerStatus
			PartnerGroup
			PartTypeReadAccess {
				ManuallySet
				AutomaticallySet
			}
		}
	}
`;

export const GQL_QUERY_COMPONENT_READ_ACCESS = gql`
	query componentReadAccess {
		componentReadAccess {
			ContractId
			Partner {
				PartnerId
				PartnerName
			}
			AdditionalData {
				PartnerGroup
				Partner {
					PartnerId
					PartnerName
				}
			}
			ComponentDetail {
				PartnerGroup
				Partner {
					PartnerId
					PartnerName
				}
			}
		}
	}
`;

export const GQL_QUERY_COMPONENT_READ_ACCESS_BY_ID = gql`
	query componentReadAccessById($contractId: String!) {
		componentReadAccessById(contractId: $contractId) {
			ContractId
			Partner {
				PartnerId
				PartnerName
			}
			AdditionalData {
				PartnerGroup
				Partner {
					PartnerId
					PartnerName
				}
			}
			ComponentDetail {
				PartnerGroup
				Partner {
					PartnerId
					PartnerName
				}
			}
		}
	}
`;

export const GQL_MUTATION_UPDATE_COMPONENT_READ_ACCESS = gql`
	mutation updateComponentReadAccess($contractId: String!, $input: UpdateComponentReadAccessRequest!) {
		updateComponentReadAccess(contractId: $contractId, input: $input) {
			ContractId
			Partner {
				PartnerId
				PartnerName
			}
			AdditionalData {
				PartnerGroup
				Partner {
					PartnerId
					PartnerName
				}
			}
			ComponentDetail {
				PartnerGroup
				Partner {
					PartnerId
					PartnerName
				}
			}
		}
	}
`;

export const GQL_QUERY_COMPONENT_WRITE_ACCESS = gql`
	query componentWriteAccess {
		componentWriteAccess {
			ContractId
			Partner {
				PartnerId
				PartnerLocation
				PartnerName
				PartnerStatus
				PartnerType
			}
			ComponentWriteAccessByContract {
				Assembler {
					PartnerId
					PartnerLocation
					PartnerName
					PartnerStatus
					PartnerType
				}

				PartnerGroup
			}
		}
	}
`;

export const GQL_MUTATION_UPDATE_COMPONENT_WRITE_ACCESS = gql`
	mutation updateComponentWriteAccess($contractId: String!, $input: UpdateComponentWriteAccessRequest!) {
		updateComponentWriteAccess(contractId: $contractId, input: $input) {
			ContractId
			Partner {
				PartnerId
				PartnerLocation
				PartnerName
				PartnerStatus
				PartnerType
			}
			ComponentWriteAccessByContract {
				Assembler {
					PartnerId
					PartnerLocation
					PartnerName
					PartnerStatus
					PartnerType
				}
				PartnerGroup
			}
		}
	}
`;

export const GQL_QUERY_COMPLIANCE_CONTENT = gql`
	query getComplianceContent {
		getComplianceContent {
			VwBrandEmployeePolicy
			BusinessPartnerPolicy
			CookiePolicy
			GroupWhistleblower
			Imprint
			PrivacyPolicy
			SystemHelp
			TermsOfUse
			ThirdPartyLicenses
			VwGroupEmployeePolicy
		}
	}
`;

export const GQL_QUERY_PRODUCT_PASS_TEMPLATE_LIST = gql`
	query productPassTemplate($where: ProductPassTemplateFilter) {
		productPassTemplate(where: $where) {
			items {
				Law
				ProductPasses {
					PartNumber
				}
				LastModified
				State
			}
		}
	}
`;
